import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';



interface AuthState {
  isLoggedIn: boolean;
 
}

const initialState: AuthState = {
  isLoggedIn: Cookies.get("token")? true : false
  
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state) => {
      state.isLoggedIn = true;
      
    },
    logout: (state) => {
      state.isLoggedIn = false;
      
    },
  },
});

export const { login, logout } = authSlice.actions;
export default authSlice.reducer;